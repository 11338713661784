import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { planPurchaseClick, plansPageView, ppWidgetLoaded } from '@wix/bi-logger-pricing-plans-data/v2';
import { IntegrationData } from '@wix/pricing-plans-router-utils';
import { getSetupFeeAmount, hasSetupFee } from '@wix/pricing-plans-utils';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { QUERY_REQUEST_LIMIT, REQUEST_FILTER_LIMIT } from '../../../constants';
import { OrdersApi, PlansApi, PremiumApi } from '../../../services';
import { Analytics } from '../../../services/analytics';
import { isUuid } from '../../../services/uuid-compression';
import { WarmupData } from '../../../services/WarmupData';
import { ListProps, CommonProps, ModalType, noModal } from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { WIDGET_TYPE } from '../../../utils/bi';
import { fetchPlanV3, createEcomCheckout, navigateToEcomCheckout } from '../../../utils/ecom';
import { is403 } from '../../../utils/errors';
import { getOrderedPlans, getOrderedVisiblePlans } from '../../../utils/plan-visibility';
import { retry401 } from '../../../utils/retry401';
import { captureViewerException } from '../../../utils/viewer-errors';
import { SettingsReader } from '../DefaultSettingsAdapter';
import { Router } from './Router';

export class ListController {
  constructor(
    protected setProps: (props: Partial<CommonProps & ListProps>) => void,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected flowAPI: ControllerFlowAPI,
    protected router: Router,
    protected plansApi: PlansApi,
    protected ordersApi: OrdersApi,
    protected premiumApi: PremiumApi,
    protected analytics: Analytics,
    protected settings: SettingsReader,
    protected demoPlans: PublicPlan[],
    protected warmupData: WarmupData,
    protected readonly hasMultiplePages: boolean,
    protected readonly isOnEcom: boolean,
  ) {}

  async initialize(integrationData: IntegrationData) {
    return this.update(integrationData);
  }

  async update(integrationData: IntegrationData, publicPlans?: PublicPlan[]) {
    const { plans } = publicPlans
      ? { plans: this.orderPlans(publicPlans, integrationData) }
      : await this.fetchAndOrderPlans(integrationData);

    if (this.flowAPI.environment.isViewer) {
      this.flowAPI.bi?.report(
        plansPageView({
          referralId: integrationData.biOptions?.referralId,
          referral_info: integrationData.biOptions?.referralInfo,
          widgetType: this.hasMultiplePages ? WIDGET_TYPE.mpaPage : WIDGET_TYPE.page,
        }),
      );
    }
    this.analytics.addProductImpression(plans);

    this.setProps({
      overrideAppTitle: integrationData.title,
      overrideAppSubtitle: integrationData.subtitle,
      plans,
      selectPlan: (plan) => this.selectPlan(plan, integrationData),
      navigateToHomePage: () => this.router.gotoHomePage(),
      widgetLoaded: () =>
        this.flowAPI.bi?.report(
          ppWidgetLoaded({
            referralId: integrationData.biOptions?.referralId,
            referral_info: integrationData.biOptions?.referralInfo,
            widgetType: this.hasMultiplePages ? WIDGET_TYPE.mpaPage : WIDGET_TYPE.page,
          }),
        ),
    });
  }

  fetchAndOrderPlans = async (integrationData?: IntegrationData) => {
    const integrationErrorContext = { contexts: { integrationData: { data: JSON.stringify(integrationData) } } };

    let plans: PublicPlan[] = [];

    const validPlanIds = integrationData?.planIds?.filter((planId) => isUuid(planId));

    if (integrationData?.planIds?.length !== validPlanIds?.length) {
      captureViewerException(this.flowAPI, new Error('Invalid plan ids'), integrationErrorContext);
    }

    const hasPlanIds = !!integrationData?.planIds?.length && integrationData.planIds.length > 0;
    const hasValidPlanIds = !!validPlanIds && validPlanIds.length > 0;

    if (hasPlanIds && !hasValidPlanIds) {
      return { plans: [] };
    }

    try {
      if (validPlanIds) {
        plans = await this.warmupData.cache('list.plans', () =>
          retry401(this.wixCodeApi, () => this.plansApi.query({ include: validPlanIds })),
        );
      } else {
        const hiddenPlans = this.settings.uuidAsArray('hiddenPlans');
        const exceedsFilterLimit = hiddenPlans.length > REQUEST_FILTER_LIMIT;
        plans = await this.warmupData.cache('list.plans', () =>
          retry401(this.wixCodeApi, () =>
            exceedsFilterLimit
              ? this.plansApi.query({ limit: QUERY_REQUEST_LIMIT })
              : this.plansApi.query({ exclude: hiddenPlans }),
          ),
        );
      }
    } catch (e) {
      captureViewerException(this.flowAPI, e, {
        ...integrationErrorContext,
        additionalIgnorePredicates: [is403],
      });
    }

    return { plans: this.orderPlans(plans, integrationData) };
  };

  orderPlans = (plans: PublicPlan[], integrationData: IntegrationData | undefined): PublicPlan[] => {
    const useFixture = !plans.length && this.wixCodeApi.window.viewMode === 'Editor';
    return useFixture
      ? this.demoPlans
      : integrationData?.planIds
      ? getOrderedPlans(plans, this.settings)
      : getOrderedVisiblePlans(plans, this.settings);
  };

  protected selectPlan = async (plan: PublicPlan, integrationData: IntegrationData) => {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.PlanSelected);
    this.flowAPI.panoramaClient?.transaction(PackagePickerInteractions.PlanSelected).start();
    const planId = plan.id;

    if (!planId) {
      this.flowAPI.reportError('Plan has no id.');
      return;
    }

    this.setProps({ selectedPlanId: planId });
    if (this.wixCodeApi.window.viewMode !== 'Site') {
      this.setProps({
        modal: { type: ModalType.PurchaseInPreview, onClose: this.closeModal },
        selectedPlanId: undefined,
      });
      this.flowAPI.fedops.interactionEnded(PackagePickerInteractions.PlanSelected);
      this.flowAPI.panoramaClient?.transaction(PackagePickerInteractions.PlanSelected).finish();
      return;
    }

    this.flowAPI.bi?.report(
      planPurchaseClick({
        planGuid: plan.id!,
        widgetType: 'page',
        setupFeeAmount: hasSetupFee(plan) ? Math.round(Number(getSetupFeeAmount(plan)) * 100) : undefined,
      }),
    );
    this.analytics.clickProduct(plan);

    if (this.isOnEcom) {
      await this.selectPlanEcom(plan, integrationData);
    } else {
      this.router.gotoCheckout(plan, integrationData);
    }

    this.flowAPI.fedops.interactionEnded(PackagePickerInteractions.PlanSelected);
    this.flowAPI.panoramaClient?.transaction(PackagePickerInteractions.PlanSelected).finish();
  };

  private closeModal = () => this.setProps({ modal: noModal });

  private selectPlanEcom = async (plan: PublicPlan, integrationData: IntegrationData) => {
    const navigateToCustomization = plan.formId || plan.allowFutureStartDate;
    if (navigateToCustomization) {
      await this.router.gotoCustomization(plan, integrationData);
    } else {
      try {
        const planV3 = await fetchPlanV3({ id: plan.id! }, this.flowAPI.httpClient);
        const ecomCheckout = await createEcomCheckout({
          plan: planV3,
          flowAPI: this.flowAPI,
          integrationData,
        });
        await navigateToEcomCheckout({
          checkoutId: ecomCheckout.id!,
          planId: plan.id!,
          wixCodeApi: this.wixCodeApi,
          integrationData,
        });
      } catch (error) {
        const errorMessage = this.flowAPI.errorHandler.getResolvedError(error).message;
        this.setProps({ errorMessage });
        captureViewerException(this.flowAPI, error, { tags: { interaction: PackagePickerInteractions.PlanSelected } });
      }
    }
  };
}
